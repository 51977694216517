.full-container{
    width: 100vw;
    height: 100vh;
}

.fixed-background-bg {
    width: 100vw;
    height: 100vh;
    position:fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
}
.page-center {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  display: block;
}

footer {
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
    height: 50px;
    color: white;
}

.view-area {
    margin: 0 auto;
    width:100%;
    max-width: 1000px;
    padding:24px;
    margin-top: 70px;
    h2 {
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        margin: 24px 0;
        color: #949292;
    }
    .section {
        h2 {
            
        }  
        .credit-input {
            display: flex;
            flex-wrap: nowrap;
            font-size: 29px;
            background: white;
            box-shadow: 0 5px 10px 0 rgba(132, 163, 178 ,0.4);
            width: 100%;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            height: 100px;
            padding: 2px;
            max-width: 800px;
            margin: 0 auto;
            .icon {
                padding: 30px;
                display: flex;
            }
            input {
                display: block;
                text-align: center;
                line-height: 50px;
                border: 0;
                flex-grow: 1;
                background: transparent;
                padding-right: 100px;
                font-size: 29px;
                color: #646e75;
            }
        }

        .block-button {
            background: #0c9eb1;
            color: #ffffff;
            font-size: 24px;
            justify-content: center;
            &.newuser {
                cursor: pointer;
                background-color: #d65b71;
                margin-bottom: 24px;
            }
        }
    }
}

.card {
    width: 90%;
    margin: 0 auto;
    max-width: 800px;
    background: #ffffff;
    padding: 16px;
    border-radius: 7px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 7px rgba(0, 0, 0 ,0.4);
    transition: all .3s ease-in;
    &.working {
        background: #997e24 !important;
        color: #FFFFFF !important;
    }
    &.success {
        background: #275f07 !important;
        color: #FFFFFF !important;
    }
    &.error {
        background: #b4291f !important;
        color: #FFFFFF !important;
    }
    &.newuser  {
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .label{
            text-align: center;
            pointer-events: none;
        }
    }
}

.stamp {
    padding:0 !important;
    overflow: hidden;
    .single {
        display: flex;
        align-items: center;
        flex-grow: 1;
        background: #0c9eb1;
        padding: 20px 16px;
        color: #ffffff;
        height: 100px;
        font-size: 22px;
        font-weight: bold;
        cursor: pointer;
        transition: all .3s ease-in;
        svg {
            margin-right: 20px;
        }
        &.working {
            background: #997e24 !important;
            color: #FFFFFF !important;
        }
        &.success {
            background: #275f07 !important;
            color: #FFFFFF !important;
        }
        &.error {
            background: #b4291f !important;
            color: #FFFFFF !important;
        }
    }
    .multiple{
        padding: 0 20px;
        font-weight: bold;
        background: #ffffff;
        color: #5a5a5a;
        font-size: 22px;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
    }
}
.single {
    .icon{
        display: flex;
        padding: 10px;
        width: 100px;
    }
}
.credit {
    >* {
        cursor: pointer;
    }
    color: #1565C0;
    .icon{
        display: flex;
        padding: 10px;
        width: 100px;
    }
    .label{
        width: 50%;
        flex-grow: 1;
        .title {
            font-size: 22px;
            text-transform: none;
            text-align: left;
            color: inherit;
            margin:0;
        }

    }
    .quantity{
        max-width: 30%;
        width: 150px;
        border-left: 2px dashed #ced1d4;
        padding: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
}

.reward {
    color: #1565C0;
    >* {
        cursor: pointer;
    }
    .icon {
        display: flex;
        padding: 10px;
        width: 100px;
    }
    .label {
        width: 50%;
        flex-grow: 1;
        .title {
            font-size: 22px;
            text-transform: none;
            text-align: left;
            color: inherit;
            margin:0;
        }
        .exp {
            font-size: 15px;
            text-transform: none;
        }
    }
    .quantity {
        max-width: 30%;
        width: 150px;
        border-left: 2px dashed #ced1d4;
        padding: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
}


.bundle {
    box-shadow: 0 5px 10px 0 rgba(132 ,163, 178 , 0.4);
    justify-content: space-between;
    color: #1565C0;
    position: relative;
    height: auto;
    user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    .icon {
        display: flex;
        padding: 10px;
        width: 100px;
    }
    .label {
        width: 50%;
        flex-grow: 1;
        .title {
            font-size: 22px;
            text-transform: none;
            text-align: left;
            color: inherit;
            margin:0;
        }
        .exp {
            font-size: 15px;
            text-transform: none;
        }
    }
    .quantity {
        max-width: 30%;
        width: 150px;
        border-left: 2px dashed #ced1d4;
        padding: 16px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
    }
    
}

.add-modal {
    position:fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.88);
    color:white;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    .modal{
        width:80%;
            max-width: 1000px;
        .card {
            padding:0;
            overflow:hidden;
            
            input {
                flex-grow: 1;
                border: 0;
                line-height: 50px;
                text-align: center;
                font-family: inherit;
                font-size: 29px;
                color: #173c48;
            }
            .decrease, .increase {
                width: 100px;
                height: 100px;
                background: #ccd2d4;
                color: #2d4b54;
                display:flex;
                align-items: center;
                justify-content: center;
                font-size: 42px;
                cursor: pointer;
                user-select: none;
                font-weight: 500;
                margin: -12px 0;
            }
        }

        .title {
            line-height: 1.75;
            text-align: center;
            font-size: 24px;
        }

        .btns {
            display:flex;
            align-items: center;
            justify-content: center;
        }

        button{
            padding: 30px 60px;
            border-radius: 10px;
            margin: 30px 15px;
            font-size: 28px;
            box-sizing: border-box;
            cursor: pointer;
            outline: none;
            display: inline-block;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            text-align: center;
            border: 0;
            color: #000000;
            background-color: #ffffff;
            box-shadow: 0 4px 0 0 #bababa;
            &.confirm {
                color: #ffffff;
                background-color: #0c9eb1;
                box-shadow: 0 4px 0 0 #228cac;
            }

            &[disabled]{
                color: #ffffff;
                background-color: #708285;
                box-shadow: 0 0px 0 0 #708285;
            }
        }

        .error{
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            width: 90%;
            text-align: center;
            margin: 30px auto 0 auto;
            box-shadow: 0 0 8px rgba(255, 255 ,255 , 0.5);
            background: #b30a0a;
            padding: 25px;
            border-radius: 7px;
            max-width: 800px;;
        }

        .success{
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            width: 90%;
            text-align: center;
            margin: 30px auto 0 auto;
            box-shadow: 0 0 8px rgba(255, 255 ,255 , 0.5);
            background: #0d6b05;
            padding: 25px;
            border-radius: 7px;
            max-width: 800px;;
        }
     
    }
}

.field {
    position: relative;
    padding:12px 0 !important;
    label {
        position:absolute;
        color:#1565C0;
        top:8px;
        left:8px;
        font-size: 14px;
        font-weight: 600;
        font-style: italic;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
    }
    .field-icon {
        width:32px;
        height:32px;
        color: #22282b;
        flex-grow: 0;
        position:absolute;
        top:50%;
        left:24px;
        z-index: 1;
        transform:translateY(-50%)

    }

    input {
        flex-grow: 1;
        padding:0  80px;
    }
    .error {
        position: absolute;
        bottom: 0;
        color: #d42408;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
    }
}

.history-block {
    width: 90%;
    margin: 0 auto;
    max-width: 800px;
    margin-top: 24px;
    display: block;
    background: #fff;
    padding: 0px;
    border-radius: 7px;
    box-shadow: 0 5px 10px 0 rgba(132, 163 ,178 , 0.4);
    justify-content: space-between;
    color: #232323;
    position: relative;
    height: 100px;
    font-size: 12px;
    overflow: hidden;

    .top {
        display: flex;
        padding: 10px;
        width: 100%;
        height: 40px;
        align-items: center;
        .number {
            font-weight: bold;
            margin-right: 16px;
        }
        .time {
            color: #6d6b6b;
            flex-grow: 1;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .customer {
        display: flex;
        align-items: center;
        height: 60px;
        text-align: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        background: #0c9eb1;
        color: #fff;
        cursor: pointer;
    }
}

.otp {
    position:fixed;
    top:0;
    left: 0;
    width:100vw;
    height: 100vh;
    z-index: 99999999;
    background-color: rgba(0, 0, 0 ,0.9);
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    >div.page-center {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
            margin:15px;
            text-align: center;
            font-family: inherit;
            font-size: 28px;
        }
        .card {
            padding:0 !important;
            min-width: 800px;
            input {
                padding:12px;
                background:transparent;
                border:0;
                text-align: center;
                font-family: inherit;
                line-height:60px;
                font-size: 42px;
                width:100%;
                min-width: none;
                
            }
        }

        .error{
            margin: 15px;
            text-align: center;
            font-family: inherit;
            font-size: 20px;
            color: #ef2121;
            font-weight: 800;
        }

        .btns {

            display: flex;
            align-items: center;
            justify-content: center;
            button {
                padding: 30px 60px;
                border-radius: 10px;
                margin: 30px 15px;
                font-size: 28px;
                box-sizing: border-box;
                cursor: pointer;
                outline: none;
                display: inline-block;
                overflow: hidden;
                position: relative;
                text-decoration: none;
                text-align: center;
                border: 0;
                color: #000000;
                background-color: #ffffff;
                box-shadow: 0 4px 0 0 #bababa;

                &.confirm {
                    color: #ffffff;
                    background-color: #0c9eb1;
                    box-shadow: 0 4px 0 0 #228cac;
                }
            }
        }
       
    }
}